<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_bento_capela_03.png"
          ></v-img>
          <p style="font-size: 0.6em; width:100%; text-align: center;">
            Vista da Capela de Nossa Senhora das Mercês em dia de festa. Fonte:
            Jornal A Sirene, Ed. 19, outubro de 2017. Autor: Silmara Filgueiras.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Capela de Nossa Senhora das Mercês"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A Capela de Nossa Senhora das Mercês está implantada em terreno
                de cota mais elevada em relação ao restante do núcleo urbano de
                Bento Rodrigues. O templo, que já se encontrava em uso no início
                do século XIX, segue a tipologia arquitetônica das construções
                religiosas do período colonial, com pé-direito duplo e planta em
                formato retangular. O adro gramado é limitado por muro baixo de
                alvenaria de pedras, sendo que à sua esquerda (norte) e aos
                fundos (oeste) encontra-se o cemitério. A fachada frontal é
                composta por porta centralizada de madeira almofadada, por duas
                janelas sineiras na altura do coro e por um óculo centralizado
                na empena, que é encimada por uma cruz latina – símbolo da
                Igreja Católica. Os vãos da porta e das janelas possuem vergas
                em arco abatido e enquadramento de madeira.
              </p>
              <p>
                O sistema construtivo compõe-se por estrutura autônoma de
                madeira vedada por pau a pique rebocado e com pintura branca. O
                telhado é disposto em duas águas com cumeeira perpendicular à
                fachada frontal, coberto por telhas cerâmicas curvas sobre
                engradamento de madeira; possui beiral com guarda-pó e
                cachorros. Internamente, o coro e púlpito possuem balaustrada de
                madeira. Os retábulos colaterais ao arco-cruzeiro, o
                arco-cruzeiro e retábulo-mor são feitos em madeira entalhada,
                com policromia e douramento. Apresenta forro tabuado de madeira,
                piso cerâmico na capela-mor, ardósia na nave e assoalho no coro.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Sobre a origem e particularidades da irmandade de Nossa Senhora das Mercês"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A história de Nossa Senhora das Mercês tem origem espanhola,
                ocorrida aproximadamente em 1218 (século XIII), período marcado
                pela dominação dos mouros que tomavam parte da Península Ibérica
                e obrigavam os cristãos a se tornarem seus cativos. Estes só
                alcançariam a liberdade se convertidos à fé islâmica. A relação
                duradoura e conflituosa entre cristãos e muçulmanos esteve
                diretamente ligada ao histórico de Nossa Senhora das Mercês como
                intercessora dos fiéis escravizados.
              </p>
              <p>
                Nas Minas Setecentistas, a devoção foi praticada pelos crioulos,
                os negros nascidos nos domínios portugueses da América, na
                condição de cativos ou forros. Estes últimos se filiariam em
                agradecimento à graça concedida, enquanto aqueles em virtude da
                intercessão que ainda estaria por vir. A devoção iniciada para a
                libertação dos cristãos brancos sob o poder dos mouros seria
                apropriada em um novo contexto, pois embora convertidos ao
                catolicismo, os africanos e seus descendentes permaneciam em
                cativeiro.
              </p>
              <p>
                As devoções a Nossa Senhora possuíam várias interpretações,
                sendo adaptadas de formas distintas, principalmente pelos negros
                escravizados e seus descendentes. Em suas diferentes invocações,
                a Virgem poderia representar tanto uma intercessora à libertação
                do cativeiro em vida, como das almas no purgatório; e este
                também foi o caso da Senhora das Mercês. A primeira irmandade
                religiosa criada para seu culto nas Minas foi em Vila Rica na
                década de 1740, ocupando um altar lateral na capela do Senhor
                Bom Jesus dos Perdões de Antônio Dias.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col cols="12" md="5" class="d-flex flex-column justify-center">
          <img width="100%" src="../../assets/sobre_cultura_bento_07.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Imagem de N. Sra. das Mercês em procissão da celebração da santa.
            Foto: Larissa Pinto/ Jornal A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center flex-row-reverse">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A Irmandade de Nossa Senhora das Mercês de Mariana foi criada em
                1749 por crioulos que se entendiam como “nacionais do Reino e
                Conquista de Portugal”. Embora muitas associações de homens
                negros não impusessem restrições para entrada de membros, os
                fiéis mercedários estipularam em seu Livro de Compromisso que
                não seriam admitidos naturais da “Ethiopia”, exceto os da “Ilha
                de São Thomé”, por serem considerados seus semelhantes. Assim,
                os indivíduos identificados como crioulos buscavam se distinguir
                dos africanos, mesmo que estes tenham nascido em terras
                africanas que também correspondessem às conquistas e domínios
                portugueses. Ao fazer o pedido para entrar na irmandade, os
                fiéis deveriam informar nome dos pais, pátria e onde foram
                batizados, visto que não seriam admitidos ladrões, vagabundos,
                feiticeiros e revoltosos. O Livro de Compromisso ressalta ainda
                que brancos e pardos seriam aceitos, tendo em vista a
                necessidade de membros que soubessem ler, escrever e calcular
                para a ocupação de alguns cargos dirigentes.
              </p>
              <p>
                Nas cidades mineiras do século XVIII, pertencer a uma irmandade
                era garantia de uma rede de relações sociais possíveis, auxílio
                mútuo, demarcação de lugar ocupado em uma sociedade
                hierarquizada e desigual, além dos benefícios religiosos que
                proporcionariam a salvação após a morte. As irmandades
                religiosas no período colonial, de forma geral, consistiam em
                agrupamentos de pessoas que se uniam por interesses e devoções
                em comum a partir de um santo protetor, geralmente divididas por
                critérios como cor, condição, naturalidade ou profissão. Nas
                irmandades havia hierarquias e desigualdades, pois pertenciam a
                um contexto específico colonialista e escravocrata.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col cols="12" md="5" class="d-flex flex-column justify-center">
          <img width="100%" src="../../assets/sobre_cultura_bento_08.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Vista da parte frontal e superior da Capela de N. Sra. das Mercês
            adornada para a festa. Foto: Larissa Pinto/ Jornal A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Contudo, tal estruturação de grupos pode ser fluida, já que os
                homens brancos geralmente entravam para as irmandades do
                Santíssimo Sacramento, Nossa Senhora da Conceição, São Miguel e
                Almas e Bom Jesus dos Passos. Em meados do XVIII, surgiriam
                ainda as Ordens Terceiras de São Francisco de Assis e Nossa
                Senhora do Carmo, que também atraíam as “elites” locais. Os
                negros, africanos escravizados ou forros, se associavam às
                Irmandades de Nossa Senhora do Rosário, São Benedito e Santa
                Efigênia; já os pardos a São Gonçalo ou São José; enquanto os
                crioulos, à Irmandade de Nossa Senhora das Mercês
              </p>
              <p>
                No século XVIII em Minas, o termo crioulo se referia ao negro
                nascido no Brasil com mãe de origem africana, nascendo escravo
                quando filho de mãe escrava e livre quando filho de mãe forra. O
                crioulo, portanto, se diferenciava do negro, termo usado para
                denominar o africano traficado, podendo ser escravizado ou
                forro. Já o termo pardo seria usado para os nativos (nascidos no
                Brasil) de alguma ascendência africana, fazendo referência à
                tonalidade da pele, se tratando principalmente de filhos gerados
                entre africanos e europeus.
              </p>
              <p>
                As irmandades consistiam em espaços de socialização onde
                ocorriam trocas e ressignificações culturais, se transformavam
                em uma certeza de encontro com seus semelhantes a partir da
                prática dos ritos e da vivência religiosa.
              </p>
            </template>
          </Card>
        </v-col>

        <v-col cols="12" md="5" class="d-flex flex-column justify-center">
          <img width="100%" src="../../assets/sobre_cultura_bento_09.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Fiéis carregam a imagem da santa, em procissão de celebração de N.
            Sra. das Mercês. Foto: Larissa Pinto/ Jornal A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center flex-row-reverse">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O dia da Festa de Nossa Senhora das Mercês representava o grande
                momento de encontro dos fiéis reunidos em seus templos, que eram
                construídos pelos próprios irmãos para sua participação ativa na
                vida religiosa e para a realização das missas e dos rituais.
              </p>
              <p>
                É importante mencionar que no período colonial, das Minas
                Setecentistas, vivia-se sob o estilo de época Barroco,
                caracterizado pela riqueza de detalhes e pelo exagero e, pela
                visão, eram despertados os outros sentidos. O imaginário criado
                a partir do morrer bem e da salvação das almas pôde, então, ser
                materializado e externalizado na vivência ritual. Durante o
                período colonial, houve uma grande valorização da pompa dos
                rituais católicos, principalmente quanto aos ritos fúnebres. O
                Barroco representaria de forma luxuosa o esplendor do céu na
                Terra, pois era a expressão máxima da salvação.
              </p>
            </template>
          </Card>

          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A morte barroca, tanto na Europa como na América portuguesa,
                também seria representada por seu caráter festivo, sendo
                reforçada ainda pelas tradições africanas. Era um momento do
                espetáculo, onde ostentação e contrição caminhavam no mesmo
                passo, sendo por vezes acrescidas da ‘algazarra dos negros’, em
                uma simbiose entre o sagrado e o profano. Os escravizados
                africanos ressignificavam suas tradições e se apropriavam do
                sistema simbólico dominante, juntamente ao Barroco e sua
                expressividade. As formas de expressar suas crenças e construir
                seus rituais eram também caracterizadas pela estética barroca,
                que foi sendo construída não apenas nas artes, mas em todos os
                setores da cultura.
              </p>
              <p>
                Celebrada em 24 de setembro, a festa de Nossa Senhora das Mercês
                deveria ser sempre realizada no domingo, mesmo acontecendo
                posteriormente ao dia 24. A Mesa Diretora geralmente se reunia
                para seu planejamento em julho, e a festa envolvia
                fundamentalmente missa e procissão.
              </p>

              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>

        <v-col cols="12" md="5" class="d-flex flex-column justify-center">
          <img
            width="100%"
            src="../../assets/sobre_territorio_bento_manifestacoes_culturais_01.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Banda toca em frente à Capela de N. Sra. das Mercês. Foto: Flora
            Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="mb-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 14px;
}
</style>
